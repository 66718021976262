
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SkyHubUser } from '../models/SkyHubUser';
import { AuthClient } from '@/services/auth';

@Component<ConfirmationPage>({})
export default class ConfirmationPage extends Vue {
  @Prop({ default: false })
  private isLoggedIn!: SkyHubUser;

  @Prop({ default: false })
  private isEmailVerified!: boolean;

  private emailVerificationSent = false;

  private async mounted() {
    if (this.$route.query.refresh === 'true') {
      return;
    }
    await AuthClient.getInstance().sendEmailVerification();
  }

  private async sendEmailConfirmation() {
    await AuthClient.getInstance().sendEmailVerification();
    this.emailVerificationSent = true;
  }
}
