var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col h-full" }, [
    _c("div", { staticClass: "flex items-center justify-center h-full" }, [
      _c(
        "div",
        { staticClass: "w-2/3 p-8 border border-gray-500 lg:w-1/2 xl:w-1/3" },
        [
          true
            ? _c("div", {}, [
                _c("h1", { staticClass: "mb-4 font-bold" }, [
                  _vm._v(" Check your email to confirm your new account ")
                ]),
                _c(
                  "div",
                  { staticClass: "m-12 text-center" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-4xl",
                      attrs: { icon: ["fas", "envelope"] }
                    })
                  ],
                  1
                ),
                _c("p", { staticClass: "px-16 text-center" }, [
                  _vm._v(
                    " To ensure account security, we sent you a confirmation email. Click the link in the email to complete your account. "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "flex justify-center mt-4 space-x-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "w-3/12",
                        attrs: { to: { name: "home" } }
                      },
                      [
                        _c("button", { staticClass: "w-full skyhub-button" }, [
                          _vm._v("Ok")
                        ])
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "skyhub-button",
                        on: { click: _vm.sendEmailConfirmation }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.emailVerificationSent
                                ? "Confirmation email sent"
                                : "Re-send confirmation email"
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          false
            ? _c("div", {}, [
                _c("h1", { staticClass: "font-bold headline" }, [
                  _vm._v("Login or create an account")
                ]),
                _c(
                  "div",
                  { staticClass: "flex justify-end space-x-4" },
                  [
                    _c("router-link", { attrs: { to: { name: "login" } } }, [
                      _c("button", { staticClass: "skyhub-button" }, [
                        _vm._v("Log in")
                      ])
                    ]),
                    _c("router-link", { attrs: { to: { name: "signup" } } }, [
                      _c("button", { staticClass: "skyhub-button" }, [
                        _vm._v("Create account")
                      ])
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          false
            ? _c("div", {}, [
                _c("h1", { staticClass: "mb-4 font-bold text-left headline" }, [
                  _vm._v("Account Verified!")
                ]),
                _c("p", { staticClass: "text-center" }, [
                  _vm._v("Your account is good to go!")
                ]),
                _c(
                  "div",
                  { staticClass: "flex justify-center" },
                  [
                    _c("router-link", { attrs: { to: { name: "home" } } }, [
                      _c("button", { staticClass: "mt-2 skyhub-button" }, [
                        _vm._v("Home")
                      ])
                    ])
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }